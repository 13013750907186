import { Component, ElementRef, HostListener } from "@angular/core";
import { AutoLogoutService } from "./_helpers/auto-logout.service";
import { Router, NavigationEnd } from "@angular/router";
import { AuthService } from "./_services/auth.service";
import { GoogleAnalyticsService } from "./_services/google-analytics.service";

// declare let gtag: Function;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  constructor(
    private autoLogout: AutoLogoutService,
    public router: Router,
    private auth: AuthService,
    private gas: GoogleAnalyticsService
  ) {
    this.router.events.subscribe((event) => {
      // if (event instanceof NavigationEnd) {
      //   // console.log(event.urlAfterRedirects);
      //   if (!this.auth.isProspectLogin) {
      //     gtag('config', 'UA-199007566-1',
      //       {
      //         'page_path': event.urlAfterRedirects
      //       }
      //     );

      //   } else {
      //     const prosId = localStorage.getItem("ACTIVA_PROSPECT_ID");
      //     gtag('config', 'UA-199007566-1',
      //       {
      //         'user_id': prosId,
      //         'page_path': event.urlAfterRedirects
      //       }
      //     );
      //   }
      // }
      if (event instanceof NavigationEnd) {
        this.gas.sendConfig(event);
      }
    });
  }

  title = "welcome-app";
}
