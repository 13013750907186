<div class="home">
    <div class="uk-container uk-container-xlarge center-container">
        <div class="activa-logo">
            <img src="./assets/img/static/svg-activa.svg" alt="Activa logo">
        </div>
        <div class="welcome-text">
            <h3>{{ 'HOME.WELCOME' | translate }}<br>
                <span>Activa Showroom</span>
            </h3>
        </div>
        <div class="lang-box">
            <div class="lang-text">
                <span>{{ 'HOME.LANG' | translate }}</span>
            </div>
            <div class="lang-btns">
                <button class="fr" (click)="start('fr')" [ngClass]="{'active': frLangIsOn}">
                    <img src="./assets/img/static/franceflag.png" alt="French">
                    <span>Français</span>
                </button>
                <button class="en" (click)="start('en')" [ngClass]="{'active': enLangIsOn}">
                    <img src="./assets/img/static/ukflag.png" alt="English">
                    <span>English</span>
                </button>
            </div>
        </div>
    </div>
</div>