import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class PageService {
  constructor(
    private pageTitle: Title,
    private translate: TranslateService,
    private router: Router,
    private http: HttpClient
  ) {}

  // get session lang if available else set language
  get globalLang() {
    const sessionLang = localStorage.getItem("ACTIVA_WELC_APP_LANG");
    if (Boolean(sessionLang)) {
      this.translate.use(sessionLang);
      return sessionLang;
    } else {
      this.translate.use("fr");
      localStorage.setItem("ACTIVA_WELC_APP_LANG", "fr");
      return "fr";
    }
  }

  // set the global language and store it
  set globalLang(lang: string) {
    this.translate.use(lang);
    localStorage.setItem("ACTIVA_WELC_APP_LANG", lang);
  }

  // set page title based on language
  setPageTitle(fr: string, en: string) {
    const lang = this.globalLang;
    lang === "en" ? this.pageTitle.setTitle(en) : this.pageTitle.setTitle(fr);
  }

  // back button
  goToPage(page: string, param?: {}) {
    if (Boolean(param)) {
      this.router.navigate([`/welcome/${page}`], { queryParams: { ...param } });
    } else {
      this.router.navigate([`/welcome/${page}`]);
    }
  }

  translateWithParam(key: string, i18VariableKey: string, val: string) {
    return this.translate.get(key, { [i18VariableKey]: val });
  }

  getAllQuotes() {
    return this.http.get("./assets/quotes/activaQuotes.json");
  }

  getRandomQuote(allQuotes) {
    let quote: any;
    const preferedQuotes = allQuotes[`${this.globalLang}`];
    let luckyNum = Math.random() * preferedQuotes.length;
    luckyNum = Math.floor(luckyNum);
    quote = preferedQuotes[luckyNum];
    return quote;
  }

  signContract(contract) {
    // set a variable in the local storage indicating contract is signed
    localStorage.setItem("ACTIVA_CONTRACT_SIGNED", "true");
    this.goToPage("/menu/successful-subscription");
  }

  get isContractSigned() {
    /* eslint-disable */
    let val: boolean = false;
    Boolean(localStorage.getItem("ACTIVA_CONTRACT_SIGNED"))
      ? (val = true)
      : (val = false);
    return val;
  }

  clearSubscriptionSession() {
    localStorage.removeItem("ACTIVA_CONTRACT_SIGNED");
  }

  updateProspectLogs(data: any) {
    if (Object.keys(data).length)
      return this.http.post(`${environment.baseUrl}/log`, data);
  }

  registerUserImage(phone: string, blob: any) {
    // const headers = new HttpHeaders();
    // headers.set("username", phone);
    // headers.set("Ocp-Apim-Subscription-Key", "72d61f797cd44373a23cc4f972c84d30");
    const httpOptions = {
      headers: new HttpHeaders({ 'galleryname': 'be423757-3e88-4dc8-9cfe-89558218d233', 'x-api-key': "5cd8a5bd49c5c287b87e88f7966b1a063ad2621eab3387b1060ca813", 'username': phone }),
    };
    
    return this.http.post(`${environment.faceUrl}v2/api/v2/face/register`, blob, httpOptions);
  }

  deleteUserImage(phone: string, blob: any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'galleryname': 'be423757-3e88-4dc8-9cfe-89558218d233', 'x-api-key': "5cd8a5bd49c5c287b87e88f7966b1a063ad2621eab3387b1060ca813", 'username': phone }),
    };
    return this.http.post(`${environment.faceUrl}v2/api/v2/face/remove`, blob,httpOptions);
  }

  verifyUserImage(blob: any) {
   // console.log(blob)
   // const headers = new HttpHeaders();
   // headers.set("galleryname", "be423757-3e88-4dc8-9cfe-89558218d233");
   // headers.set("x-api-key", "5cd8a5bd49c5c287b87e88f7966b1a063ad2621eab3387b1060ca813");
   const httpOptions = {
      headers: new HttpHeaders({ 'galleryname': 'be423757-3e88-4dc8-9cfe-89558218d233', 'x-api-key': "5cd8a5bd49c5c287b87e88f7966b1a063ad2621eab3387b1060ca813" }),
    };
    return this.http.post(`${environment.faceUrl}`, blob, httpOptions);

    // console.log(blob)
  }

  // store session if prospect is on iframe
  // get isIframeOpen() {
  //   // console.log(Boolean(localStorage.getItem("ACTIVA_PROSPECT_IS_ON_IFRAME")));
  //   return Boolean(localStorage.getItem("ACTIVA_PROSPECT_IS_ON_IFRAME"));
  // }
}
