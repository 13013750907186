import { Injectable } from '@angular/core';
import { GoogleAnalyticsService } from './google-analytics.service';

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsClickServiceService {

  constructor(private googleAnalyticsService: GoogleAnalyticsService) { }

  public openIframe(serviceId) {
    this
      .googleAnalyticsService
      .eventEmitter('open_service', serviceId);
      // .eventEmitterNoEventValue('open_iframe', 'menu', 'insurance', serviceId);
  }

  public englishToFrenchStatistics(langId) {
    this
      .googleAnalyticsService
      .eventEmitter('user_lang', langId);
      // .eventEmitterNoEventValue('en_french_stats', 'activa_showroom', 'language', langId);
  }
}
