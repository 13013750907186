import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { PageService } from "./page.service";

@Injectable({
  providedIn: "root",
})
export class GlobaldataService {
  day = new Date();
  userLogs = new BehaviorSubject({
    date: `${this.day.getDate()}-${
      this.day.getMonth() + 1
    }-${this.day.getFullYear()}`,
    logs: [],
  });
  userData = new BehaviorSubject({
    prospect_name: "default",
    prospect_phone: "67000000",
  });

  currentLogs = this.userLogs.asObservable();
  currentUser = this.userData.asObservable();

  constructor(private pageService: PageService) {
    const Data = localStorage.getItem("userData");
    const Logs = localStorage.getItem("userLogs");

    if (Data && Logs) {
      console.log("*********Updating Global States**********");
      this.updateUserData(JSON.parse(Data));
      this.updateUserLogs(JSON.parse(Logs));
    }
  }

  updateUserLogs(data: any) {
    // console.log('^^^^^^ Adding Logs  ^^^^^^^^')
    // console.log(data)
    if (Object.keys(data).length) {
      localStorage.setItem("userLogs", JSON.stringify(data));
      this.userLogs.next(data);
    }
  }

  updateUserData(data: any) {
    localStorage.setItem("userData", JSON.stringify(data));
    this.userData.next(data);
  }
}
