import { Injectable } from "@angular/core";
import { ProspectLoginDTO, ProspectSignupDTO } from "../_models/prospect-dtos";
import { RedacteurDTO } from "../_models/redacteur-dto";
import { HttpClient } from "@angular/common/http";
import { PageService } from "./page.service";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { GlobaldataService } from "./globaldata.service";
import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";


@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(
    private pageService: PageService,
    private router: Router,
    private dialogRef: MatDialog,
    private userData: GlobaldataService,
    private http: HttpClient
  ) {}

  // check if prospect is logged-in or not logged-in
  get isProspectLogin() {
    const prosId = localStorage.getItem("ACTIVA_REDACTEUR_USERNAME");
    return Boolean(prosId);
  }

  // check if the password needs to be updated
  get isPasswordExpired() {
    const prosId = localStorage.getItem("ACTIVA_REDACTEUR_IS_PASSWORD_EXPIRED");
    return Boolean(prosId);
  }

  // login prospect
  login(prospect: ProspectLoginDTO) {
    const phone = prospect.prospect_phone;
    return this.http.post(`${environment.loginUrl}/login/prospect`, { phone });
  }
  loginX(phone: string) {
    return this.http.post(`${environment.loginUrl}/login/prospect`, { phone });
  }

  // login prospect
  register(prospect: ProspectSignupDTO) {
    const user = {
      name: prospect.prospect_name,
      email: prospect.prospect_email,
      phone: prospect.prospect_phone,
    };
    return this.http.post(`${environment.loginUrl}/register/prospect`, user);
  }

  // logout prospect
  logout() {
    this.router.navigate(["/welcome/thankyou"]);
  }

  // login rédacteur
  loginRedacteur(redacteur: RedacteurDTO) {
    const username = redacteur.redacteur_username;
    const password = redacteur.redacteur_password;
    const fromAdmin = true;
    //const pos = redacteur.redacteur_pos.code;
    console.log("About to call the login API");
    return this.http.post(`${environment.loginUrl}/user/login`, { username, password, fromAdmin});
  }
}
