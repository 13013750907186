import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
} from "@angular/common/http";
import { Observable, throwError, TimeoutError } from "rxjs";
import { catchError, timeout } from "rxjs/operators";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { ToastService } from "../_models/toast.service";
import { HttpErrResponse } from "../_models/HttpErrResponse";
import { GlobaldataService } from "../_services/globaldata.service";
import { environment } from "src/environments/environment";

@Injectable()
export class MiddlemanInterceptor implements HttpInterceptor {
  globalUserData: any = {};
  constructor(
    private router: Router,
    private toast: ToastService,
    private dialogRef: MatDialog,
    private GlobalData: GlobaldataService,
  ) {
    this.getUserData();
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    //console.log(request.url)
    if (
      this.router.url.startsWith("/welcome/start") &&
      request.url === `${environment.faceUrl}api/v2/face/register`
    ) {
      request = request.clone({
        headers: new HttpHeaders({
	  'Access-Control-Allow-Origin': '*',
          'x-api-key': "5cd8a5bd49c5c287b87e88f7966b1a063ad2621eab3387b1060ca813", 
          galleryName: "be423757-3e88-4dc8-9cfe-89558218d233",
          username: `${localStorage.getItem("ACTIVA_HEADER_REG_username")}`,
        }),
      });
    } else if (
      this.router.url.startsWith("/welcome/start") &&
      request.url === `${environment.faceUrl}api/v2/face/verify`
    ) {
      request = request.clone({
        headers: new HttpHeaders({
          'x-api-key': "5cd8a5bd49c5c287b87e88f7966b1a063ad2621eab3387b1060ca813",
          galleryName: "be423757-3e88-4dc8-9cfe-89558218d233",
        }),
      });
    } else if (
      this.router.url.startsWith("/welcome/start") &&
      request.url === `${environment.faceUrl}remove`
    ) {
      // 51b63bfb-8616-468a-9fb1-9faa211d3326
      request = request.clone({
        headers: new HttpHeaders({
	  'Access-Control-Allow-Origin': '*',
          'x-api-key': "5cd8a5bd49c5c287b87e88f7966b1a063ad2621eab3387b1060ca813",
          galleryName: "be423757-3e88-4dc8-9cfe-89558218d233",
          username: `${localStorage.getItem("ACTIVA_HEADER_REMOVE_username")}`,
        }),
      });
    } else if (
      this.router.url.startsWith("/welcome/start") &&
      request.url !== `${environment.faceUrl}register`
    ) {
      request = request.clone({
        headers: request.headers.append(
          "Authorization",
          "Basic " +
            btoa(
              `${this.globalUserData.prospect_name}:${this.globalUserData.prospect_phone}`
            )
        ),
      });
    } 
    else if (this.router.url.startsWith("/welcome/start")) {
      console.log("The request was intercepted");
      console.log(request);
      request = request.clone({
        headers: request.headers.append(
          "Authorization",
          "Basic " +
            btoa(
              `${this.globalUserData.prospect_name}:${this.globalUserData.prospect_phone}`
            )
        ),
      });
    }else if (this.router.url.startsWith("/welcome/menu/users") || this.router.url.startsWith("/welcome/menu/monitoring") || this.router.url.startsWith("/welcome/menu/password-update") || this.router.url.startsWith("/welcome/menu/historique-souscription")) {
        console.log(request.params.get('token'));
        if(request.params.get('token')) {
          request = request.clone({
            headers: request.headers.append(
              "Ocp-Apim-Subscription-Key",
              `${environment.ocpApimSubscriptionKey}`
            ).append(
              "Authorization",
              "Bearer "+request.params.get('token')
            )
          });
        }else{
          request = request.clone({
            headers: request.headers.append(
              "Ocp-Apim-Subscription-Key",
              `${environment.ocpApimSubscriptionKey}`
            ).append(
              "Authorization",
              "Basic ZGVmYXVsdDo2NzAwMDAwMA=="
            )
          });
        }
    } else {
      request = request.clone({
        headers: new HttpHeaders({
          Authorization:
            "Basic " +
            btoa(
              `${this.globalUserData.prospect_name}:${this.globalUserData.prospect_phone}`
            ),
        }),
      });
    }

    return next.handle(request).pipe(
      // timeout(1200000),
      catchError((error: HttpErrResponse) => {
      //   if (error instanceof TimeoutError) {
      //     this.toast.showInfoToast("Le serveur est momentanément indisponible, veuillez réessayer plus tard...");
      //     return throwError('Timeout Exception');
      //  }
        // Implement a switch to pass around our error status. This is implemented here so as to use it globally
        switch (error.status) {
          case 401:
            return throwError(error);
            break;
          case 403:
            this.toast.showInfoToast(
              "Acces Denied :You dont have permission to perform this operation"
            );
            return throwError(error);
            break;
          case 404:
            if (Boolean(localStorage.getItem("REGFORM_VERIFICATION"))) {
              localStorage.removeItem("REGFORM_VERIFICATION");
            } else {
              // this.toast.showErrorToast("Prospect does not exist!");
            }
            return throwError(error);
            break;
          case 400:
            let message = " ";
            if (error.error.data) {
              const key = Object.values(error.error.data);
              key.forEach((element) => {
                message = message + element + "\n";
              });

              this.toast.showErrorToast(message);
            } else {
              this.toast.showErrorToast(error?.error?.message);
            }

            return throwError(error);
            break;
          case 500:
            this.toast.showInfoToast("Please try again.");
            return throwError(error);
            break;

          case 503:
            this.toast.showInfoToast("Please try again.");
            return throwError(error);
            break;
          default:
            if (error?.error?.message) {
              this.toast.showInfoToast(error?.error?.message);
            } else {
              /*this.toast.showInfoToast(
                "Connection lost, try again in a moment!."
              );*/
            }
            return throwError(error);
            break;
        }
      })
    );
  }
  // Get the Global user data
  getUserData() {
    this.GlobalData.currentUser.subscribe({
      next: (data) => {
        this.globalUserData = data;
      },
    });
  }
}
