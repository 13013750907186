import { Injectable, NgZone, ElementRef, HostListener } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "../_services/auth.service";
import { ToastService } from "../_models/toast.service";
import { PageService } from "../_services/page.service";
import { IdleService } from "../_services/idle.service";
import { VerifyIdle, RefreshIdle } from "../_models/idle";

const MINUTES_UNITL_AUTO_LOGOUT = 5; // in Minutes
const CHECK_INTERVALL = 240000; // in ms
const CHECK_INTERVALL_ALERT = 1000; // in ms
const STORE_KEY = "";

@Injectable({
  providedIn: "root",
})
export class AutoLogoutService {
  public idleAlert: boolean = false; /* eslint-disable */
  public sessionIsExpired: boolean = false; /* eslint-disable */

  constructor(
    private auth: AuthService,
    private router: Router,
    private ngZone: NgZone,
    private toastService: ToastService,
    private activatedRoute: ActivatedRoute,
    private pageServices: PageService,
    private idleService: IdleService
  ) {
    //this.check();
    this.initListener();
    this.initIntervalIdle();
    this.initIntervalAlert();
  }

  get lastAction() {
    // tslint:disable-next-line: radix
    return parseInt(localStorage.getItem("STORE_KEY"));
  }
  set lastAction(value) {
    localStorage.setItem("STORE_KEY", "" + value);
  }

  initListener() {
    this.ngZone.runOutsideAngular(() => {
      document.body.addEventListener("click", () => this.reset());
    });
  }

  initIntervalIdle() {
    this.ngZone.runOutsideAngular(() => {
      setInterval(() => {
        if (this.auth.isProspectLogin && Boolean(this.idleService.idleToken)) {
          this.checkIdle();
        }
      }, CHECK_INTERVALL);
    });
  }

  initIntervalAlert() {
    this.ngZone.runOutsideAngular(() => {
      setInterval(() => {
        //this.check();
      }, CHECK_INTERVALL_ALERT);
    });
  }

  reset() {
    this.lastAction = Date.now();
    if (this.auth.isProspectLogin && Boolean(this.idleService.idleToken)) {
      this.idleService
        .refreshToken(this.idleService.idleToken)
        .subscribe((data: RefreshIdle) => {
          this.idleAlert = false;
        });
    }
  }

  checkIdle() {
    const lang = localStorage.getItem("ACTIVA_WELC_APP_LANG");
    this.idleService
      .verifyToken(this.idleService.idleToken)
      .subscribe(({ expired, session_time }: VerifyIdle) => {
        if (session_time >= 3.5 * 60) {
          this.idleAlert = true;
          this.sessionIsExpired = expired;
          lang === "en"
            ? this.toastService.showInfoToast(
                `Your session will expire in 90 sec(s), please click to stay connected`,
                "Session is inactive!",
                10000
              )
            : this.toastService.showInfoToast(
                `Votre session expirera dans 90 sec (s), veuillez cliquer pour rester connecté`,
                "La session est inactive!",
                10000
              );
        }
        if (session_time >= 4 * 60) {
          this.idleAlert = true;
          lang === "en"
            ? this.toastService.showInfoToast(
                `Your session will expire in 60 sec(s), please click to stay connected`,
                "Session is inactive!",
                10000
              )
            : this.toastService.showInfoToast(
                `Votre session expirera dans 60 sec (s), veuillez cliquer pour rester connecté`,
                "La session est inactive!",
                10000
              );
          // this.sessionIsExpired = expired;
        }
        if (session_time >= 4.5 * 60) {
          this.idleAlert = true;
          lang === "en"
            ? this.toastService.showInfoToast(
                `Your session will expire in 30 sec(s), please click to stay connected`,
                "Session is inactive!",
                10000
              )
            : this.toastService.showInfoToast(
                `Votre session expirera dans 30 sec (s), veuillez cliquer pour rester connecté`,
                "La session est inactive!",
                10000
              );
        }
        if (session_time >= 5 * 60) {
          this.idleAlert = true;
          lang === "en"
            ? this.toastService.showInfoToast(
                `Logging you out...`,
                "Session is inactive!",
                10000
              )
            : this.toastService.showInfoToast(
                `Déconnexion en cours…`,
                "La session est inactive!",
                10000
              );
          this.sessionIsExpired = expired;
        }
        if (expired) {
          this.ngZone.run(() => {
            if (this.auth.isProspectLogin) {
              this.auth.logout();
            }
          });
        }
      });
  }

  check() {
    const now = Date.now();
    const timeleft = this.lastAction + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
    const diff = timeleft - now;

    if (this.router.url.startsWith("/welcome/home")) {
      this.reset();
    }
    // Should redirect after 2mins of inactivity
    this.ngZone.run(() => { 
      if (this.router.url.startsWith("/welcome/start") && diff <= 180000) {
        this.reset();
        
        this.router.navigate(["/welcom/home"]);
      }
    });
  }
}
