import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../_services/auth.service";
import { PageService } from "../_services/page.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private pageService: PageService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.auth.isProspectLogin) return true; /* eslint-disable */
    // redirect to welcome page if user is not logged-in
    this.pageService.goToPage("home");
    return false;
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return new Promise((resolve) => {
      if (this.auth.isProspectLogin) resolve(true); /* eslint-disable */
      // redirect to welcome page if user is not logged-in
      this.pageService.goToPage("home");
      resolve(false);
    }).then((e) => {
      return true;
    });
  }
}
