export const environment = {
  production: true,
  baseUrl: "https://software.group-activa.com:44305/activa_server",
  idleBaseUrl: "https://software.group-activa.com:44305/notify",
  faceUrl: "https://activaapimanagement.azure-api.net/facial/",
  autoService: "https://sh-ci-staging-costapp.azurewebsites.net/#/", //"https://software.group-activa.com:44305/costapps/#/step-one/",
  riskService:
    "https://software.group-activa.com:44305/costapps/#/multirisque-habitation-step1/",
  condoleanceService:
    "https://software.group-activa.com:44305/costapps/#/condoleance-stepOne/",
  ladyStartupService:
    "https://software.group-activa.com:44305/costapps/#/ladyStartUp-step1/",
  chatbotService: "https://activa-chatbot.sevengps.net/full/#/seven/load",
  getInsurrance:
    "https://software.group-activa.com:44305/costapps/#/all-assurance/",
  googleTrackingId: "UA-199007566-1",
  loginUrl:
    //"http://cmr-l-ivan:3000",
    //"https://cidev-api-sh.azurewebsites.net",
    "https://civ-prod-api-sh.azurewebsites.net",
  automobileBaseUrl:
    "https://activaapimanagement.azure-api.net/tarifiktor-civ-prod",
  subscriptionBaseUrl:
    "https://activaapimanagement.azure-api.net/showroomcivms-civ-prod",
  printingBaseUrl:
    "https://activaapimanagement.azure-api.net/docgeneratorciv-prod",
  automobileExtraBaseUrl:
    //"http://192.168.6.192:3000",
    //"http://cmr-l-ivan:3000",
    "https://civ-prod-api-sh.azurewebsites.net",
  assureBaseUrl:
    "https://activaapimanagement.azure-api.net/activaorassapiprod",
  assureAuthApiUser:
    "CIV-SH",
  autoAuthApiUser:
    "CIV-SH",
  assureAuthApiPassword: 
    "35ef91449cd10834bde0ca66b7f42761",
  autoAuthApiPassword: 
    "15706f0d7303d97747000969221b8b45",
  identProcess: 
    "CIV-SH",
   ocpApimSubscriptionKey:
    "74feb0c153a14d86baf224b9aab04a4a"
};
