import { Injectable } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';

declare let gtag: (...params: any[]) => void;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor() { }

  // public eventEmitter(
  //   eventName: string,
  //   eventCategory: string,
  //   eventAction: string,
  //   eventLabel: string = null,
  //   eventValue: number = null) {
  //   gtag('event', eventName, {
  //     eventCategory: eventCategory,
  //     eventLabel: eventLabel,
  //     eventAction: eventAction,
  //     eventValue: eventValue
  //   })
  // }

  public eventEmitterNoEventValue(
    eventName: string,
    eventCategory: string,
    eventAction: string,
    eventLabel: string = null) {
    gtag('event', eventName, {
      eventCategory: eventCategory,
      eventLabel: eventLabel,
      eventAction: eventAction
    })
  }

  // public setCurrentUser(userId: string) {
  //   // console.log(userId);
  //   gtag('set', 'user_id', userId);
  // }



  public eventEmitter(
    eventName: string,
    eventCategory: string,
    eventLabel: string = null,
    eventValue: number = null
  ) {
    gtag('event', eventName, {
      event_category: eventCategory,
      event_label: eventLabel,
      value: eventValue
    });
  }

  public sendConfig(event: NavigationEnd) {
    gtag('config', environment.googleTrackingId,
      {
        page_path: event.urlAfterRedirects,
        send_page_view: true
      });
  }

  public setCurrentUser(userId: string) {
    gtag('set',
      {
        user_id: userId
      });
  }
}
