import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { PageService } from "src/app/_services/page.service";
import { GoogleAnalyticsClickServiceService } from 'src/app/_services/google-analytics-click-service.service';

@Component({
  selector: "app-home-screen",
  templateUrl: "./home-screen.component.html",
  styleUrls: ["./home-screen.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HomeScreenComponent implements OnInit {
  // declare variables
  enLangIsOn: boolean = true; /* eslint-disable */
  frLangIsOn: boolean = false; /* eslint-disable */
  sessionLang: string;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private pageService: PageService,
    public googleAnalyticsClick: GoogleAnalyticsClickServiceService
  ) { }

  ngOnInit(): void {
    this.getSessionLang();
    this.setPageTitle();
  }

  // get default lang from the session if exit and set translator lang
  getSessionLang() {
    this.sessionLang = this.pageService.globalLang;
    if (this.sessionLang === "en") {
      this.enLangIsOn = true;
      this.frLangIsOn = false;
    } else if (this.sessionLang === "fr") {
      this.frLangIsOn = true;
      this.enLangIsOn = false;
    }
  }

  // set page title
  setPageTitle() {
    this.pageService.setPageTitle(
      "Bienvenue à Activa Showroom",
      "Welcome to Activa Showroom"
    );
  }

  // listen to selected language and then proceed
  start(lang: string) {
    this.googleAnalyticsClick.englishToFrenchStatistics(lang);
    this.pageService.globalLang = lang;
    this.router.navigate(["/welcome/start"]);
  }
}
