import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class IdleService {
  constructor(private httpClient: HttpClient) {}

  // store new token
  /**
   * @param {string} token - Boolean to indicate if start time is passed; by default, it's true
   */
  storeNewToken(token: string) {
    const sessionparams = { token: token };
    return this.httpClient.post(
      `${environment.idleBaseUrl}/token/store`,
      sessionparams
    );
  }

  // verify token
  /**
   * @param {string} token - Boolean to indicate if start time is passed; by default, it's true
   */
  verifyToken(token: string) {
    const params = new HttpParams().append("token", token);
    return this.httpClient.get(`${environment.idleBaseUrl}/token/verify`, {
      params,
    });
  }

  // refresh token
  /**
   * @param {string} token - Boolean to indicate if start time is passed; by default, it's true
   */
  refreshToken(token: string) {
    const params = new HttpParams().append("token", token);
    return this.httpClient.put(`${environment.idleBaseUrl}/token/refresh`, {
      token,
    });
  }

  // get localStorage idle token
  /**
   * @param {string} token - Boolean to indicate if start time is passed; by default, it's true
   */
  get idleToken() {
    return localStorage.getItem("ACTIVA_IDLE_TOKEN");
  }
}
